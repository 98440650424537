














import { defineComponent, ref, reactive } from '@vue/composition-api';
import { useAuth } from '@/admin/auth';
import { useNotification } from '@/composition/notification';

export default defineComponent({
  name: 'PasswordReminderVerify',
  setup(props, context) {
    const { remindPasswordVerify } = useAuth();
    const notification = useNotification();
    const formData = reactive({
      newPassword: '',
    });
    const isSending = ref(false);

    const code = context.root.$route.query.code as string;
    if (!code) {
      context.root.$router.replace('/passwordreminder');
    }

    const send = async () => {
      isSending.value = true;
      try {
        await remindPasswordVerify(code, formData.newPassword);
        notification.notify('新しいパスワードを設定しました');
        context.root.$router.replace('/signin');
      } catch (error) {
        notification.error(error);
      }
      isSending.value = false;
    }

    return { formData, isSending, send };
  },
});
